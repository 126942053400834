/*------------------------------>
	Layout > Footer
<------------------------------*/

	.footer {

		@include breakpoint($max-md) {
			padding-bottom: 70px;
		}
	}

	/*	Footer Content
	//------------------------------*/

	.footer-content {
		@include font-size(18);
		@include font-smoothing;

		position: relative;
		z-index: 1;

		background-color: #ebebeb;
		color: $black;
		padding: 40px 0 150px;

		ul {

			li {
				display: block;
			}
		}

		a {
			color: $black;

			&:hover {
				color: $blue;
			}
		}

		@include breakpoint($min-md) {
			@include font-size(20);

			padding-bottom: 230px;
		}
	}

	.footer-column {
		padding: 0 20px;
		text-align: center;

		@include breakpoint($min-sm) {
			text-align: left;
		}

		@include breakpoint($sm-lg) {
			&:first-child {
				border-right: 2px solid #D3D3D3;
			}
		}

		@include breakpoint($sm-md) {
			padding: 0 20px;

			&:nth-child(1) {
				padding: 0 40px 0 20px;
			}

			&:nth-child(2) {
				padding: 0 20px 0 40px;
			}
		}

		@include breakpoint($md-lg) {
			padding: 0 40px;
		}

		@include breakpoint($min-lg) {
			border-right: 2px solid #D3D3D3;
			padding: 0 30px 30px;

			&:last-child {
				border-right: none;
				padding-right: 0;
			}
		}

		@include breakpoint($min-xl) {
			padding: 0 60px;

			&:last-child {
				padding-right: 0;
			}
		}
	}

	.footer-column-form {

		@include breakpoint($max-lg) {
			margin-top: 40px;
		}
	}

	.footer-content-section {
		margin-bottom: 30px;
		padding-top: 15px;
	}

	.footer-content-section-title {
		@include open-sans-bold;
		@include font-size(20);
		@include line-height(30, 33);

		color: $blue;
		margin-bottom: 20px;
		text-transform: uppercase;

		@include breakpoint($max-sm) {
			text-align: center;
		}

		@include breakpoint($min-md) {
			@include font-size(22);
		}
	}

	.footer-address {
		@include font-size(18);

		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.footer-address-title {
		@include open-sans-bold;
		@include font-size(18);

		position: relative;

		color: $blue;
		display: inline-block;

		@include breakpoint($min-sm) {
			padding-right: 60px;
		}

		&:after {
			@include icon-car;
			
			content: "";
			display: block;
			margin-top: 5px;
			margin-bottom: 5px;
			margin-left: auto;
			margin-right: auto;
		
			@include breakpoint($min-sm) {
				position: absolute;

				display: inline;
				margin-bottom: 0;
				margin-top: 4px;
				margin-left: 10px;
			}
		}

		@include breakpoint($md-lg) {
			margin-right: -70px;
		}		

		@include breakpoint($lg-xl) {
			margin-right: -30px;
		}

		@include breakpoint($min-xl) {
			margin-right: -50px;
		}
	}

	.footer-map {
		margin-top: 20px;

		img {
			max-width: 100%;
			width: 345px;
		}
	}

	
	/*	Footer Meta
	//------------------------------*/

	.footer-meta {
		position: relative;
		z-index: 1;

		background-color: $blue;
		border-top: 16px solid $gold;
		padding: 145px 0 30px;

		@include breakpoint($min-md) {
			padding-top: 200px;
		}
	}

	.footer-meta-info {
		text-align: center;

		&, a {
			color: $blue-gray;
		}

		span {

			@include breakpoint($max-md) {
				color: transparent;
				display: block;
				line-height: 0.6;
			}
		}
	}


	/*	Logo
	//------------------------------*/

	.footer-logo {
		position: absolute;
		left: 50%;
		top: 0;
		z-index: 2;

		background-color: #ebebeb;
		background-image: url('../images/site/shield.png');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 225px 225px;
		border-radius: 100%;
		margin-left: -125px;
		margin-top: -155px;

		@include breakpoint($min-md) {
			background-size: 325px 325px;
			margin-left: -175px;
			margin-top: -215px;
		}

		.svg & {
			background-image: url('../images/site/shield.svg');
		}
	}

	.logo-footer {
		@include size(250, 250);

		@include breakpoint($min-md) {
			@include size(350, 350);
			padding: 20px;
		}
	}


	/*	Social Media
	//------------------------------*/

	.footer-social-media {
		text-align: center;

		@include breakpoint($min-lg) {
			text-align: left;
		}

		.navigation-items {
			float: none;
			margin: 0;
			padding: 0;
		}

		.navigation-social-item {
			
			display: inline-block;
			
			margin: 10px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.navigation-social-link {
			@include size(80);

			background-color: $blue;
			display: block;

			&:before,
			&:after {
				@include size(40);

				top:0;
				right:0;
				bottom:0;
				left:0;

				margin: auto;
			}
		}
	}


	/*	Request Information Form
	//------------------------------*/

	.footer-information-form {

		h1,
		.form_builder_required_message,
		.form_builder_required_star,
		label[for=footer_form_builder_field_2],
		label[for=footer_form_builder_field_4],
		label[for=footer_form_builder_field_6],
		label[for=footer_form_builder_field_8],
		label[for=footer_form_builder_field_9] {
			display: none;
		}

		.form_builder {

			.form_builder_radio {
				display: inline-block;
			}

			label,
			label.form_builder_for_checkbox {
				color: $black;
			}

			input.form_builder_text,
			textarea,
			label,
			label.form_builder_for_checkbox {
				@include open-sans;
				@include font-size(18);
			}
			
			fieldset {
				margin-bottom: 15px;
			}

			.form_builder_column fieldset {
				margin-bottom: 10px;
			}

			input.form_builder_submit {
				&:hover {
					background-color: $white;
					color: $blue;
				}
			}
		}

		.fs-light.fs-checkbox-focus,
		.no-touchevents & .fs-light:hover {
			
			.fs-checkbox-label {
				color: $black;
			}
		}
	}



	/*	Portal Mobile Footer
	//------------------------------*/
	
	.portal-mobile-footer {

	}

	.portal-mobile-footer-links {
		width: 100%;
		height: 70px;

		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 5;

		background-color: $blue-light;

		@include breakpoint($min-md) {
			display: none;
		}
		
		ul {
			@include reset-list;
			@include font-size(0);

			padding: 10px 0;

			li {
				width: 25%;
				display: inline-block;
				text-align: center;

				a {
					@include font-size(14);

					color: $white;
					display: block;
					text-transform: uppercase;

					@include breakpoint($max-sm) {
						@include font-size(11);
					}

					img {
						max-width: 30px;
						display: block;
						margin: 0 auto 5px;
					}
				}
			}
		}
	}