/*------------------------------>
	Layout > Page
<------------------------------*/

	.page-wrapper {
		overflow-x: hidden;
	}

	.page {
		outline: none;
	}

	.page-header {

	}

	.page-header-image {
		height: 140px;

		background-color: $blue;
		background-position: 50% 45%;

		@include breakpoint($min-md) {
			height: 200px;
		}

		&.has-image {
			height: 250px;

			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;

			@include breakpoint($min-md) {
				height: 360px;
			}
		}
	}

	.page-body {
		background-color: $white;
		padding: 40px 0;
	}

	.page-content {
		position: relative;

		padding-bottom: 50px;

		@include breakpoint($min-lg) {
			padding-bottom: 100px;
			padding-left: 55px;
		}

		&:before {
			width: 2px;
			height: 100%;

			position: absolute;
			left: 0%;

			border-left: 2px solid $gray;

			@include breakpoint($min-lg) {
				content: "";
			}
		}

		&.page-content-full {
			&:before {
				content: none;
			}
		}
	}

	.page-section {
		background-color: $gray-pale;
		padding: 20px 0;
	}

	.page-quote {
		@include open-sans-italic;
		@include font-size(20);

		text-indent: -0.4em;

		@include breakpoint($min-sm) {
			@include font-size(22);
		}

		@include breakpoint($min-lg) {
			@include font-size(28);

			line-height: 2;
			padding-left: 55px;
		}
	}

	.section-title {
		&, a {
			@include heading-1;
			@include font-size(26);

			color: $blue-light;
			line-height: 2.1;

			@include breakpoint($min-lg) {
				@include font-size(26);
			}
		}

		@include breakpoint($max-lg) {
			border-bottom: 2px solid #b7b7b7;
			padding-bottom: 10px;
			margin-bottom: 10px;
		}

		@include breakpoint($min-lg) {
			position: relative;
			text-align: right;
			
			&:after {
				width: 2px;
				height: 100%;

				position: absolute;
				top: 0;
				right: -21.75px;


				border-right: 2px solid #b7b7b7;
				content: "";
				display: inline-block;
			}
		}

		@include breakpoint($min-xl) {

			&:after {
				right: -27px;
			}
		}
	}

	.breadcrumbs {
		@include breakpoint($min-lg) {
			padding: 18px 20px 18px 55px;
		}
	}

	.breadcrumb-item {
		@include open-sans-bold;
		@include font-size(13);

		color: $black;
		text-transform: uppercase;

		@include breakpoint($min-sm) {
			@include font-size(14);
		}

		@include breakpoint($min-lg) {
			@include font-size(16);

			margin: 0 4px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.breadcrumb-link {
		color: $blue-light;

		&:hover {
			color: $black;
		}
	}