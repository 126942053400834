/*------------------------------>
	Document > Buttons
<------------------------------*/

	/*	Mixins
	//------------------------------*/

	@mixin button {
		@include transition-link;

		background: transparent;
		border: none;
		outline: none;
		padding: 0;
		-webkit-appearance: none;
	}

	@mixin button-outline {
		@include button;
		@include font-size(20);
		@include font-smoothing;

		border: 2px solid $white;
		border-radius: 6px;
		color: $white;
		line-height: 1;
		padding: 12px 16px;

		&:hover {
			background-color: $blue-light;
		}
	}

	@mixin button-blue {
		@include button;
		@include font-size(20);
		@include font-smoothing;

		background: $blue;
		border-radius: 4px;
		color: $white;
		line-height: 1;
		padding: 12px 16px;

		&:hover {
			background-color: $gold;
			color: $black;
		}
	}
	

	/*	Button Types
	//------------------------------*/

	.button {
		@include button;
	}

	.navigation-bar-button {
		@include button-outline;
	}

	.button-outline {
		@include button-outline;
	}

	.button-blue {
		@include button-blue;
	}

	.button-outline-dark {
		@include button-outline;

		border-color: $gray-dark;
		color: $gray-dark;

		&:hover {
			background-color: $gray-dark;
			color: $white;
		}
	}

	.button-white {
		@include button;
		@include font-size(20);
		@include font-smoothing;

		background-color: $white;
		border: 2px solid $white;
		border-radius: 0;
		color: $blue;
		display: inline-block;
		padding: 12px 16px;
		text-transform: uppercase;

		&:hover {
			background-color: $blue-light;
			color: $white;
		}
	}
