/*------------------------------>
	Document > Text
<------------------------------*/
	
	/*	Font Mixins (example)
	//------------------------------*/

	@mixin open-sans($font-style: normal, $font-weight: 400) {
		font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
		font-style: $font-style;
		font-weight: $font-weight;
	}

	@mixin open-sans-italic {
		@include open-sans(italic);
	}

	@mixin open-sans-bold {
		@include open-sans(normal, 600);
	}

	@mixin open-sans-bold-italic {
		@include open-sans(italic, 600);
	}


	/*	Text Styles
	//------------------------------*/

	@mixin heading-1 {
		@include font-size(36);
		@include line-height(36, 42);

		@include breakpoint($min-lg) {
			@include font-size(42);
		}
	}

	.heading-1 {
		@include heading-1;
	}

	@mixin heading-2 {
		
	}

	.heading-2 {
		@include heading-2;
	}

	@mixin heading-3 {

	}

	.heading-3 {
		@include heading-3;
	}

	@mixin heading-4 {

	}

	.heading-4 {
		@include heading-4;
	}

	@mixin heading-5 {

	}

	.heading-5 {
		@include heading-5;
	}

	@mixin heading-6 {

	}

	.heading-6 {
		@include heading-6;
	}

	@mixin navigation-link {
		letter-spacing: -0.4px;
	}

	@mixin blockquote {
		
	}

	@mixin body-text {
		@include font-size(18);
		@include line-height(18, 26);

		@include breakpoint($min-lg) {
			@include font-size(20);
		}
	}

	@mixin link {
		border-bottom: 1px solid $blue-light;
		color: $blue-light;

		&:hover {
			border-bottom-color: $black;
			color: $black;
		}
	}

	@mixin button {
		
		&:hover {

		}
	}


	/*	Text Elements
	============================================================================= */

	a {
		@include transition-link;

		text-decoration: none;
	}

	h1, h2, h3, h4, h5, h6, p, blockquote {
		font-weight: 400;
		margin: 0;
	}

	// WYSIWYG text styles

	.wysiwyg {

		* {
			margin-bottom: 0;
		}

		* + * {
			margin-top: 20px;
		}

		img,
		iframe {
			max-width: 100%;
		}

		p, ul li {
			@include body-text;
		}

		a {
			@include body-text;
			@include link;
		}

		h1, h2, h3, h4, h5, h6 {
			a {
				border-bottom: none;
			}		
		}

		hr {
			
		}

		em {
			
		}

		strong {
			
		}

		p, h2, h3, h4, h5, h6 {
			
			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		h1 {
			@include heading-1;

			@include breakpoint($min-lg) {
				margin-top: 15px;
			}
		}

		h2 {
			@include heading-2;
		}

		h3 {
			@include heading-3;
		}

		h4 {
			@include heading-4;
		}

		h5 {
			@include heading-5;
		}

		h6 {
			@include heading-6;
		}

		blockquote {

			&,
			p {
				@include blockquote;
			}

			p {
				margin: 0;
			}
		}

		ol,
		ul {
			li {
				margin-bottom: 10px;
			}

			&:not(.list-style-none)	{
				
				li {
					
				}	
			}
			

			&.list-style-none {
				padding: 0;

				li {
					margin: 0;
				}
			}
		}

		ol {
			counter-reset: li;
			padding-left: 30px;

			> li {
				position: relative;
				display: block;
				list-style: none;

				&:before {
					position: absolute;
					top: 0;
					right: 100%;

					color: $blue-light;
					content: counter(li) ". ";
					counter-increment: li;
					font-weight: 700;
					margin-right: 10px;
				}
			}

			&[type="a"] {
				> li {
					&:before {
						content: counter(li, lower-alpha) ". ";
					}
				}
			}

			&[type="A"] {
				> li {
					&:before {
						content: counter(li, upper-alpha) ". ";
					}
				}
			}

			&[type="i"] {
				> li {
					&:before {
						content: counter(li, lower-roman) ". ";
					}
				}
			}

			&[type="I"] {
				> li {
					&:before {
						content: counter(li, upper-roman) ". ";
					}
				}
			}
		}

		ul {
			list-style: none;
			padding-left: 30px;

			&:not(.list-style-none) {

				li:before {
					@include size(6);

					background-color: $blue;
					border-radius: 100%;
					content: "";
					display: block;
					float: left;
					margin: 11px 0 0 -22px;
					vertical-align: middle;
				}
			}
		}

		table {

		}

	} // .wysiwyg

	.table-wrapper {
		max-width: 100%;
		overflow: auto;
	}

	.table-fade {
		position: relative;

		&:before {
			@include gradient-horizontal(rgba(0,0,0,0), rgba(0,0,0,0.2));

			width: 20px;
			height: 100%;

			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;

			display: block;

			@include breakpoint($max-md) {
				content: "";
			}
		}
	}

	// Google CSE

	.cse .gsc-search-button input.gsc-search-button-v2, input.gsc-search-button-v2 {
		width: 70px !important;
		height: 29px !important;
	}

	.gsc-search-box-tools .gsc-search-box .gsc-input {
		height: 25px !important;
	}

	.gsc-input-box {
		height: 30px !important;
	}

	.gsst_b {
		margin-top: 6px !important;
	}
