/*------------------------------>
	Layout > Navigation
<------------------------------*/

	/*	Main Navigation
	//------------------------------*/

	.navigation {
		@include clearfix;

		ul {
			list-style: none;
			padding-left: 0;
			padding-right: 0;
			margin: 0;

			li {
				display: inline;
				margin: 0;
			}
		}
	}

	.js-navigation-hidden {
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.15s linear, visibility 0.15s linear;
		visibility: hidden;

		&.show {
			opacity: 1;
			pointer-events: all;
			visibility: visible;
		}
	}


	/* Navigation Bar */

	.header-navigation-bar {
		position: relative;

		background: $blue-gradient-dark;
		background: -moz-linear-gradient(top, $blue-gradient-dark 0%, $blue-gradient-light 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, $blue-gradient-dark), color-stop(100%, $blue-gradient-light));
		background: -webkit-linear-gradient(top, $blue-gradient-dark 0%, $blue-gradient-light 100%);
		background: -o-linear-gradient(top, $blue-gradient-dark 0%, $blue-gradient-light 100%);
		background: -ms-linear-gradient(top, $blue-gradient-dark 0%, $blue-gradient-light 100%);
		background: linear-gradient(to bottom, $blue-gradient-dark 0%, $blue-gradient-light 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-gradient-dark', endColorstr='$blue-gradient-light', GradientType=0 );
		border-bottom: 5px solid $blue;
		padding: 23px 0 23px;

		&:before {
			width: 100%;
			height: 5px;

			position: absolute;
			top: 100%;

			background: rgba(0,0,0,0);
			background: -moz-linear-gradient(top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.35)), color-stop(100%, rgba(0,0,0,0)));
			background: -webkit-linear-gradient(top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
			background: -o-linear-gradient(top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
			background: -ms-linear-gradient(top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
			content: "";
		}

		.navigation-items {
			@include font-size(0);

			position: relative;
			z-index: 2;

			&,
			li,
			li:before,
			.navigation-link {
				vertical-align: middle;
			}

			li {
				display: inline-block;
				margin-right: 20px;

				&.navigation-social-item {
					margin-right: 10px;
				}

				&:last-child {
					margin-right: 0;
				}

				&.navigation-item {

					@include breakpoint($max-xl) {
						display: none;
					}
				}
			}

			.navigation-item {
				margin-bottom: -30px;
				padding-bottom: 30px;

				&:hover {

					.navigation-toggle-button + .navigation-children {
						opacity: 1;
						pointer-events: all;
						visibility: visible;
					}
				}
			}
		}

		.navigation-item {
			
		}

		.navigation-link {
			@include button-outline;

			display: inline-block;
		}

		.navigation-children {
			@include font-size(20);

			width: 320px;

			position: absolute;
			top: 76px;
			right: 0;

			background: $blue-header-gradient-light;
			background: -moz-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(0%, $blue-header-gradient-light), color-stop(100%, $blue-header-gradient-dark));
			background: -webkit-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
			background: -o-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
			background: -ms-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
			background: linear-gradient(to bottom, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-header-gradient-light', endColorstr='$blue-header-gradient-dark', GradientType=0 );
			opacity: 0;
			padding: 20px 50px 40px;
			pointer-events: none;
			visibility: hidden;
			text-align: left;
			transition: opacity 0.2s linear, visibility 0.2s linear;

			li {
				margin: 0;

				&, a {
					@include font-size(20);

					color: $white;
					display: block;
				}

				a {
					@include font-smoothing;

					padding: 3px 0;

					&:hover {
						color: $gold;
					}
				}

				h3 {
					@include font-size(22);

					margin-bottom: 15px;
					text-transform: uppercase;
				}
			}			
		}

		@include breakpoint($max-md) {
			padding: 16px 0;
		}

		@include breakpoint($max-xl) {
			
			ul {
				text-align: right;
			}
		}

		@include breakpoint($max-md) {

			.navigation ul li {
				&.navigation-item,
				&.navigation-social-item {
					display: none;
				}
			}
		}

		@include breakpoint($min-xl) {
			ul li {
				margin-right: 20px;

				&.navigation-social-item {
					margin-right: 10px;
				}
			}
		}
	}

	.navigation-social-link {
		width: 40px;
		height: 40px;

		position: relative;

		display: block;

		&:before,
		&:after {
			@include full;

			position: absolute;
			top: 0;
			left: 0;

			content: "";
			display: block;
			transition: opacity 0.3s linear;
		}

		&:before {

		}

		&:after {
			opacity: 0;
		}

		&:hover {

			&:before {
				opacity: 0;
			}

			&:after {
				opacity: 1;
			}
		}

		&.social-account-link-facebook {

			&:before {
				@include icon-facebook-white;
			}

			&:after {
				@include icon-facebook-red;
			}
		}

		&.social-account-link-twitter {
			
			&:before {
				@include icon-twitter-white;
			}

			&:after {
				@include icon-twitter-red;
			}
		}

		&.search {
			&:before {
				@include icon-search-white;
			}

			&:after {
				@include icon-search-gold;
			}
		}
	}

	.navigation-toggle-button {
		@include button-outline;

		position: relative;

		float: left;
		padding-right: 35px;

		&:after {
			width: 0;
			height: 0;

			position: absolute;
			right: 15px;
			top: 20px;

			border-top: 7px solid $white;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			content: "";
		}

		&.open {
			&:after {
				border-bottom: 7px solid $white;
				border-top: none;
			}
		}

		&.main-navigation-button {

			@include breakpoint($min-lg) {
				min-width: 212px;
			}
		}

		@include breakpoint($max-lg) {
			@include text-indent(44px);

			width: 44px;
			height: 44px;
			min-width: 0;

			float: right;
			margin: -2px 0 0 20px;
			padding: 0;

			&:after {
				top: 18px;
			}

			&.open:after {
				top: 16px;
			}
		}
	}


	/* Navigation Panel */	

	.header-navigation-panel {
		width: 100%;

		position: absolute;
		top: 100%;
		left: 0;

		background: $blue-header-gradient-light;
		background: -moz-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, $blue-header-gradient-light), color-stop(100%, $blue-header-gradient-dark));
		background: -webkit-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -o-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -ms-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: linear-gradient(to bottom, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-header-gradient-light', endColorstr='$blue-header-gradient-dark', GradientType=0 );
		padding: 25px 0;

		.navigation ul li.navigation-child-item {
			display: block;
			margin-bottom: 6px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint($min-lg) {
			padding: 35px 0;
		}

		.navigation-additional {

			li {

				&,
				.navigation-link {
					@include font-size(18);

					color: $white;
					display: block;
				}

				h3, a {
					@include font-smoothing;
				}

				h3 {
					@include font-size(20);

					margin-bottom: 15px;
					text-transform: uppercase;
				}

				a {

					&:hover {
						color: $gold;
					}
				}

				&.navigation-social-item {
					display: inline-block;
					margin-right: 20px;
					vertical-align: top;

					.search {
						@include text-indent(40px);
					}
				}
			}

			&.navigation-additional-social {
				margin-top: 30px;
				text-align: center;

				@include breakpoint($min-md) {
					display: none;
				}
			}

			.navigation-link {
				margin-bottom: 10px;

				&:hover {
					// background-color: $gray-dark;
				}
			}

			@include breakpoint($max-sm) {
				margin-top: 25px;
			}

			@include breakpoint($sm-md) {
				padding-left: 10px;
			}

			@include breakpoint($md-xl) {
				padding-left: 20px;
			}

			@include breakpoint($min-xl) {
				display: none;
			}
		}

		.navigation-main {
			clear: both;

			.navigation-link {
				@include font-smoothing;

				color: $white;

				&:hover {
					color: $gold;
				}

				@include breakpoint($max-xl) {
					@include font-size(18);
				}
			}

			.navigation-item {
				display: block;
				line-height: 44px;
				padding: 0 0 10px;
				
				&:after {
					width: 100%;
					height: 1px;

					border-bottom: 1px solid $white;
					content: "";
					display: block;
				}

				&:last-child {
					border-right: none;
				}

				> .navigation-link {
					text-transform: uppercase;
				}

				@include breakpoint($min-sm) {
					width: 100%;
				}

				@include breakpoint($max-xl) {

					.navigation-children {
						border-top: 1px solid #fff;
						display: none;
						margin-bottom: 20px;

						.navigation-child-item {
							line-height: 1.5;
						}
					}

					&.open .navigation-children {
						display: block;
					}
				}

				@include breakpoint($min-xl) {
					width: 16.667%;

					border-right: 1px solid $white;
					float: left;
					line-height: 1.2;
					padding: 0 20px 20px;
					text-align: center;

					&:after {
						width: 80%;

						content: none;
						margin: 20px auto 0;
					}

					.navigation-child-item {
						margin-bottom: 15px;
					}
				}
			}
		}

		.navigation-item-expand {
			@include button;
			@include size(44);
			@include text-indent(44px);

			position: relative;

			color: $white;
			float: right;

			&:after {
				@include full-width;
				@include full-height;

				position: absolute;
				top: 0;
				left: 0;

				content: "+";
				font-size: 20px;
				line-height: 44px;
				text-align: center;
				text-indent: 0;
			}

			@include breakpoint($min-xl) {
				display: none;
			}
		}

		.navigation-item.open .navigation-item-expand {

			&:after {
				content: "–";
				font-size: 18px;
			}
		}

		.navigation-children {
			padding-top: 20px;
		}

		.navigation-child-item {

		}

		.navigation-child-link {

		}
	}

	
	/*	Subnavigation
	//------------------------------*/

	.subnavigation-handle {
		@include breakpoint($min-lg) {
			display: none;
		}
	}

	.subnavigation-links {
			padding-top: 15px;
	}

	.subnavigation-link {
		@include font-size(18px);

		position: relative;

		border-bottom: 1px solid $gray-border;
		color: $gray-dark;
		padding: 12px 6px 12px 0;

		&:after {
			width: 2px;
			height: 100%;

			position: absolute;
			top: 0;
			z-index: 1;

			border-right: 2px solid $blue-light;
			box-shadow: 0 1px 0 $blue-light;
			display: block;

			@include breakpoint($min-lg) {
				right: -21.75px;
			}

			@include breakpoint($min-xl) {
				right: -27px;
			}
		}

		&:hover,
		&.subnavigation-link-active {
			color: $blue-light;

			&:after {
				content: "";
			}
		}

		@include breakpoint($max-lg) {
			border-left: 2px solid $gray-border;
			padding-left: 20px;

			&:after {
				left: -2px;
			}
		}

		@include breakpoint($min-lg) {
			@include font-size(18);
		}
	}

	.fs-light.fs-navigation-toggle-handle.fs-navigation-enabled {
		@include open-sans-italic;
		@include font-size(18);

		border-color: $gray-border;
		border-left-width: 2px;
		color: $blue-light;
		outline: none;
		padding: 0 6px 0 15px;

		&:after {
			height: 2px;
			background: $blue-light;
			box-shadow: 0 4px 0 $blue-light,0 -4px 0 $blue-light;
		}

		&.fs-navigation-open {
			// border-color: $blue;

			&:after {
				height: 15px;
				background: transparent;
				box-shadow: none;
				color: $blue-light;
			}
		}
	}

	.fs-light.fs-navigation-toggle-handle.fs-navigation-enabled.fs-navigation-focus,
	.no-touchevents .fs-light.fs-navigation-toggle-handle.fs-navigation-enabled:hover {
		background-color: $white;
	}

	.fs-light.fs-navigation-toggle-nav.fs-navigation-enabled {
		border: none;
		outline: none;
		padding: 0;
	}





