/*------------------------------>
	Templates > Blog
<------------------------------*/

	/*	Blog Listing
	//------------------------------*/

	.blog-page-title {
		margin-bottom: 10px;
	}

	.blog-page-subtitle {
		color: #777777;
	}

	.blog-posts {
		margin-top: 20px;
	}

	.blog-post {
		border-bottom: 1px solid $gray;
		padding: 20px 0 30px;

		&:first-child {
			border-top: 1px solid $gray;
		}
	}

	.blog-post-header{
		padding-bottom: 20px;
	}

	.blog-post-title {
		margin-bottom: 6px;

		a {
			@include font-size(20);

			color: $blue-light;

			&:hover {
				color: $gray-dark;
			}

			@include breakpoint($min-md) {
				@include font-size(24);
			}
		}
	}

	.blog-post-meta {

	}

	.blog-post-categories {
		margin-bottom: 6px;

		a {
			@include font-size(14);

			color: $gray-dark;
			letter-spacing: 1px;
			text-transform: uppercase;

			&:hover {
				color: $blue-light;
			}
		}
	}

	.blog-post-author {
		.wysiwyg & {
			color: $gray-dark;
		}
	}

	.blog-post-date {

	}

	.blog-post-blurb {

	}

	.blog-post-footer {

	}

	.blog-post-tags {
		padding-top: 20px;
	}

	.blog-pagination {
		@include clearfix;

		padding: 30px 0;

		a {
			margin: 0;
		}
	}

	.blog-newer-posts {
		float: left;
	}

	.blog-older-posts {
		float: right;
	}