/*------------------------------>
	Document > Helpers
<------------------------------*/

	.responsive-image {
		display: block;

		picture {
			display: block;
		}

		img {
			width: 100%;
		}
	}

	.absolute {
		position: absolute;
	}

	.relative {
		position: relative;
	}

	.fixed {
		position: fixed;
	}

	.static {
		position: static;
	}

	.none {
		display: none;
	}

	.block,
	.block_link {
		display: block;
	}

	.inline {
		display: inline;
	}

	.inline_block {
		display: inline-block;
	}

	.full_height {
		@include full-height;
	}

	.full_width {
		@include full-width;
	}

	.full {
		@include full-width;
		@include full-height;
	}

	.left,
	.block_left {
		float: left;
	}

	.block_left {
		margin: 0 20px 20px 0;
	}

	.right,
	.block_right {
		float: right;
	}

	.block_right {
		margin: 0 0 20px 20px;
	}

	.clear {
		clear: both;
	}

	.clearfix {

		&:after {
			clear: both;
			content: ".";
			display: block;
			height: 0;
			line-height: 0;
			visibility: hidden;
		}
	}

	.contain {
		overflow: hidden;
	}

	.no_select,
	.no_select * {
		user-select: none;
	}

	.hide {
		display: none !important;
		visibility: hidden;
	}

	.visually_hide {
		width: 1px;
		height: 1px;

		position: absolute;

		border: 0;
		clip: rect(0 0 0 0);
		margin: -1px;
		overflow: hidden;
		padding: 0;

		&.focusable:active,
		&.focusable:focus {
			width: auto;
			height: auto;

			position: static;

			clip: auto;
			margin: 0;
			overflow: visible;
		}
	}

	.invisible {
		visibility: hidden;
	}

	.offscreen {
		width: 1px;
		height: 1px;

		position: absolute;
		left: -999px;

		overflow: hidden;
		z-index: -999;
	}

	.print {
		display: none;
	}

	.centered {
		&,
		p {
			text-align: center;
		}
	}

	.video_frame {
		width: 100%;

		position: relative;

		padding-bottom: 56.25%;

		iframe {
			width: 100%;
			height: 100%;

			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.error {
		// background-color: transparentize($red, 0.2);
		border-radius: 20px;
		color: $white;
		line-height: 1.6;
		padding: 12px 20px;
	}
