/*------------------------------>
	Layout > Header
<------------------------------*/

	.header {
		position: relative;
		z-index: 3;
		
		background: $gray-dark;
		border-top: 3px solid $white;
	}
	

	/*	Logo
	//------------------------------*/

	.logo-header {
		position: absolute;
		top: 28px;
		left: 15px;
		z-index: 1;

		@include breakpoint($min-sm) {
			top: 25px;
			left: 30px;

			transform: none;
		}

		@include breakpoint($md-lg) {
			top: 30px;
		}

		@include breakpoint($min-lg) {
			left: 50%;
			transform: translateX(-50%);
		}

		/*
		@include breakpoint($lg-xl) {
			top: 36px;
		}
		*/

		@include breakpoint($min-lg) {
			top: 30px;
		}
		
		.logo {
			@include icon-logo-main;
			@include text-indent(44px);

			width: 230px;
			height: 18px;

			background-size: 230px 18px;

			display: block;
			text-align: center;

			@include breakpoint($min-sm) {
				width: 300px;
				height: 23px;

				background-size: 300px 23px;
			}

			/*
			@include breakpoint($min-lg) {
				width: 300px;
				height: 23px;

				background-size: 300px 23px;
			}
			*/

			@include breakpoint($min-lg) {
				width: 400px;
				height: 30px;

				background-size: 400px 30px;
			}
		}
	}
