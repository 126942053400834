/*------------------------------>
	Document > General
<------------------------------*/

	html {
		font-size: $base-font-size + px;
	}

	html,
	body {
		@include open-sans;

		background: $white;
	}

	body {
		@include font-size(16);

		min-width: 300px;

		background-color: $white;
		color: $gray-dark;
		overflow-x: hidden;
	}

	::selection {
		background: #eee;
		text-shadow: none;
	}

	hr {
		width: 100%;
		height: 1px;

		background: #eee;
		border: none;
		clear: both;
		margin: 0;
		padding: 0;

	}

	iframe {
		border: none;
	}

	menu {
		margin: 0;
		padding: 0;
	}

	audio,
	canvas,
	iframe,
	img,
	svg,
	video {
		vertical-align: middle;
	}

	figure {
		margin: 0;
	}
