/*------------------------------>
	Templates > Faculty
<------------------------------*/

	/*	Faculty Listing
	//------------------------------*/

	.faculty-member {
		margin: 30px 0 0;
		padding-right: 8px;

		@include breakpoint($min-md) {
			margin: 40px 0 0;
		}

		@include breakpoint($sm-xl) {
			&:nth-child(2n+1) {
				clear: both;
			}
		}

		@include breakpoint($min-xl) {
			&:nth-child(3n+1) {
				clear: both;
			}
		}
	}

	.faculty-member-image {
		margin-bottom: 20px;
	}

	.faculty-member-content {
		padding: 0 10px;
	}

	.faculty-member-name {
		margin-bottom: 6px;

		a {
			color: $black;
			display: inline-block;

			&:hover {
				color: $blue-light;
			}
		}
	}

	.faculty-member-department {
		color: $blue-light;
		margin-bottom: 12px;
	}

	.faculty-member-description {
		margin-bottom: 20px;
	}

	.faculty-member-contact {
		padding: 0 10px;

		a {
			@include font-size(18);
			
			display: inline-block;
			margin: 0 0 6px;
		}
	}


	/*	Faculty Detail
	//------------------------------*/

	.faculty-detail-image {
		
		margin-top: 25px;

		@include breakpoint($max-sm) {
			max-width: 100%;
		}

		@include breakpoint($min-sm) {
			max-width: 322px;

			float: left;
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}

	.faculty-detail-footer {
		clear: both;
		margin-top: 20px;
	}

