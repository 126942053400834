/*------------------------------>
	Layout > Sidebar
<------------------------------*/

	.sidebar-content {
		position: relative;

		padding-bottom: 30px;

		@include breakpoint($min-md) {
			padding-bottom: 50px;
		}

		@include breakpoint($min-lg) {
			padding-bottom: 100px;
		}

		&:before {
			width: 2px;
			height: 100%;

			position: absolute;

			border-left: 2px solid $gray;

			@include breakpoint($min-lg) {
				right: -21.75px;

				content: "";
			}

			@include breakpoint($min-xl) {
				right: -27px;
			}
		}
	}

	.sidebar {

		@include breakpoint($min-lg) {
			text-align: right;
		}
	}

	.subnavigation-link {
		display: block;
	}