/*------------------------------>
	Document > Variables
<------------------------------*/

	$base-font-size: 16;


	/*	Colors
	//------------------------------*/

	$white: #FFFFFF;
	$black: #252525;
	$true-black: #000000;

	$gray-dark: #303030;
	$gray: #a5a5a5;
	$gray-border: #d6d6d6;
	$gray-light: #e2e2e2;
	$gray-pale: #f2f2f1;
	$red: #66000e;
	$red-highlight: #7e0011;
	$red-deep: #720010;
	$red-bright: #8c0113;
	$red-light: #9d1124;
	$red-pale: #b38087;

	$blue: #052e59;
	$blue-light: #003b6c;

	$blue-gradient-dark: #062b51;
	$blue-gradient-light: #04305d;

	$blue-header-gradient-dark: #00335d;
	$blue-header-gradient-light: #004e91;

	$blue-gray: #8297ac;

	$gold: #ffcf40;


	/*	Transition Speed
	//------------------------------*/

	$default-speed: 0.15s;
	$default-delay: 0s;


	/*	Dimensions
	//------------------------------*/

	/* Icon Sprite */
	$sprite-width:  500px;
	$sprite-height: 300px;

	/* WordPress Admin Bar */
	$wpadminbar-ht-mobile:  46px;
	$wpadminbar-ht-desktop: 32px;

	/* iPad */
	$ipad-ht: 1024px;
	$ipad-wt: 768px;

	/* Header */
	$header-ht-mobile:  0px;
	$header-ht-desktop: 0px;

	/* Aspect Ratios */
	$sixteen-nine: 0.5625;
	$golden-ratio: 0.618;
	$three-two:    0.6667;
	$standard-def: 0.75;

	/* Aspect Ratio Percentages */
	$sixteen-nine-percent: 56.25%;
	$golden-ratio-percent: 61.8%;
	$standard-def-percent: 75%;
	$three-two-percent:    66.667%;


	/*	Grid Breakpoints
	//------------------------------*/

	$only-screen: 'only screen';

	$sm: 500;
	$md: 740;
	$lg: 980;
	$xl: 1220;

	$ht: 780px;

	$min-sm: $only-screen ($sm * 1px);
	$min-md: $only-screen ($md * 1px);
	$min-lg: $only-screen ($lg * 1px);
	$min-xl: $only-screen ($xl * 1px);

	$max-sm: $only-screen 0px ($sm - 1px);
	$max-md: $only-screen 0px ($md - 1px);
	$max-lg: $only-screen 0px ($lg - 1px);
	$max-xl: $only-screen 0px ($xl - 1px);

	$sm-md: $only-screen ($sm * 1px) ($md - 1px);
	$sm-lg: $only-screen ($sm * 1px) ($lg - 1px);
	$sm-xl: $only-screen ($sm * 1px) ($xl - 1px);

	$md-lg: $only-screen ($md * 1px) ($lg - 1px);
	$md-xl: $only-screen ($md * 1px) ($xl - 1px);

	$lg-xl: $only-screen ($lg * 1px) ($xl - 1px);

	$max-ht: $only-screen ($lg * 1px) (max-height $ht);
