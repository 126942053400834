/*------------------------------>
	Document > Forms
<------------------------------*/

	::-webkit-input-placeholder {
		color: #ccc;
	}

	
	::-moz-placeholder {
		color: #ccc;
	}
	
	:-ms-input-placeholder {
		color: #ccc;
	}
	
	:-moz-placeholder {
		color: #ccc;
	}

	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}

	input,
	textarea {
		border: none;
		border-radius: 0;
		padding: 0;

		-webkit-appearance: none;
	}

	input::-ms-clear {
		width: 0;
		height: 0;
	}

	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=url],
	input[type=number],
	input[type=date],
	input[type=search],
	textarea {

		&,
		form.form_builder & {
			@include open-sans;
			@include font-size(20);
			@include transition(0.25s, ease);

			background-color: $white;
			border: 2px solid #999999;
			border-radius: 4px;
			letter-spacing: -0.01em;
			padding: 12px;
			outline: none;
			vertical-align: middle;

			&:focus {
				border-color: $blue-light;
			}
		}
	}

	textarea {
		line-height: 1.5;
		resize: vertical;
	}

	input[type="submit"],
	button[type="submit"] {
		@include button;
	}


	/*	Form Builder Overrides
	//------------------------------*/

	form.form_builder {

		input.form_builder_text,
		textarea,
		label,
		label.form_builder_for_checkbox {
			@include open-sans;
			@include font-size(18);
		}

		label {
			color: #5C5C5C;
		}

		.form_builder_radio label {
			width: auto;
		}

		textarea {
			height: 80px;
		}

		input.form_builder_submit {
			@include font-size(16);
			@include font-smoothing;

			height: auto;

			background: $blue;
			line-height: 1;
			padding: 10px 30px;

			@include transition-link;

			&:hover {
				background-color: $white;
				border-color: $blue;
			}

			@include breakpoint($max-sm) {
				width: 100%;
			}
		}

		.form_builder_column {

			@include breakpoint($max-sm) {
				width: auto;

				float: none;
				margin: 0 0 10px;
			}
		}
	}

	.form_builder_required_star {
		position: absolute;
		margin-left: 5px;
	}

	.form_builder_required_message {

		.form_builder_required_star {
			position: static;
		}
	}

	.form_builder_column {
		clear: right;
	}


	/*	Formstone Checkbox Overrides
	//------------------------------*/

	.fs-checkbox.fs-light .fs-checkbox-marker {
		border: 2px solid #5C5C5C;
	}

	.fs-light.fs-checkbox-radio {
		border-color: $blue-light;

		.fs-checkbox-flag {
			background: $blue-light;
		}
	}

	.fs-checkbox.fs-light .fs-checkbox-label {
		@include font-size(18);
	}

	.fs-light.fs-checkbox-focus, .fs-checkbox-marker,
	.no-touchevents .fs-light:hover {

		.fs-checkbox-marker {
			background: $white;
			border-color: $blue-light;

			.fs-checkbox-flag {
				background: $blue-light;
			}
		}
	}


