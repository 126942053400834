/*------------------------------>
	Templates > Events
<------------------------------*/

	/*	This Month
	//------------------------------*/

	.events-month-container {
		@include clearfix;

		border-bottom: 1px solid $gray;
		padding-bottom: 15px;
	}

	.events-month {
		color: $blue-light;
	}

	.events-month-info {
		@include breakpoint($max-sm) {
			margin-bottom: 10px;
		}

		@include breakpoint($min-sm) {
			float: left;
		}		
	}

	.events-month-navigation {

		@include breakpoint($min-sm) {
			float: right;
		}
	}

	.events-month-button {
		position: relative;

		color: $blue;
		line-height: 2;

		&:hover {
			color: $gray-dark;

			&:after {
				border-left-color: $gray-dark;
				border-right-color: $gray-dark;
			}
		}

		&:after {
			@include size(0);

			position: absolute;
			top: 6px;

			border-top: 5px solid transparent;
			border-bottom: 5px solid transparent;
			content: "";
			display: block;
			transition: border-color 0.2s linear;
		}

		&:first-child {
			margin-right: 6px;
		}
	}

	.events-month-previous {
		padding-left: 14px;

		&:after {
			left: 0;

			border-right: 7px solid $blue;
		}

		@include breakpoint($max-sm) {
			float: left;

			&:after {
				top: 10px;
			}
		}
	}

	.events-month-next {
		padding-right: 14px;

		&:after {
			right: 0;

			border-left: 7px solid $blue;
		}

		@include breakpoint($max-sm) {
			float: right;

			&:after {
				top: 10px;
			}
		}
	}

	.event-item {
		@include clearfix();

		border-bottom: 1px solid $gray;
		padding: 20px 0;
	}

	.event-title-container {

		@include breakpoint($min-lg) {
			overflow: hidden;
		}
	}

	.event-title {
		margin-top: 10px;

		a {
			@include font-size(20);

			color: $gray-dark;

			&:hover {
				color: $blue-light;
			}
		}

		@include breakpoint($min-lg) {
			margin-top: 0;
		}
	}

	.event-meta {

		.event-detail & {
			margin-top: 5px;

			&,
			a {
				@include font-size(22);
			}
		}

		.events-this-month & {
			@include font-size(18);

			a {
				margin-top: 0;
			}

			@include breakpoint($min-lg) {
				min-width: 160px;
				float: left;
				margin-right: 10px;
			}
		}

		span {
			display: block;
			margin-top: 0;
		}
	}

	.event-date {
		color: $blue-light;

		a {
			border: none;

			.events-this-month & {
				@include font-size(18);
			}
		}

		@include breakpoint($max-lg) {

			br {
				display: none;
			}
		}
	}

	.event-time {
		color: $gray-dark;
	}

	.no-events {
		margin-top: 30px;
	}

	/*	UI Datepicker
	//------------------------------*/

	
	.events-calendars {
		margin-bottom: 30px;

		@include breakpoint($min-lg) {
			margin-bottom: 50px;
		}
	}

	.datepicker {

		@include breakpoint($max-md) {
			max-width: 436px;
			margin: 0 auto;
		}
		
		table {
			table-layout: fixed;

			th, td {
				@include open-sans;
			}
			
			thead {
				@include font-size(14);

				background-color: $blue-light;
				text-align: center;

				th {
					color: $white;
					padding: 8px 0 4px;
				}
			}

			td {
				@include font-size(0);

				position: relative;

				background: #E7E7E7;
				border: 1px solid white;
				line-height: 0;
				padding: 14.2857143% 0 0;

				&,
				a,
				span {
					text-align: center;
				}

				a {
					@include font-size(18);
					@include full;

					position: absolute;
					top: 0;
					left: 0;

					line-height: 3;
					padding: 0;
					text-align: center;

					@include breakpoint(0px 400px) {
						line-height: 2.5;
					}

					@include breakpoint($min-lg) {
						@include font-size(16);
					}

					@include breakpoint($min-xl) {
						@include font-size(18);
					}
				}
			}

			a { 
				display: block; padding: 10px;
			}
		}

		.ui-datepicker,
		.ui-datepicker.ui-datepicker-multi {
			width: auto !important;

			position: relative;

			@include breakpoint($max-sm) {
				padding-bottom: 44px;
			}

			@include breakpoint(500px 800px) {
				padding: 0 35px;
			}
		}

		.ui-widget-content {
			border: none;
		}

		.ui-datepicker-multi .ui-datepicker-group table {
			width: 100%;

			margin: 0;
		}

		.ui-widget-header {
			position: static;

			background: transparent;
			border: none;
			border-radius: 0;
		}

		.ui-datepicker .ui-datepicker-title {
			margin: 0;
			text-align: left;
		}

		.ui-state-highlight,
		.ui-widget-content .ui-state-highlight,
		.ui-widget-header .ui-state-highlight {
			border: none;
			color: $blue-light;
		}

		.ui-state-highlight.ui-state-hover,
		.ui-widget-content .ui-state-highlight.ui-state-hover,
		.ui-widget-header .ui-state-highlight.ui-state-hover {
			color: $white;
		}

		.ui-state-disabled,
		.ui-widget-content .ui-state-disabled,
		.ui-widget-header .ui-state-disabled {
			opacity: 1;
		}

		.ui-state-default {
			background: #E7E7E7;
			border: none;
		}

		.ui-state-active {
			background: white;
			color: $blue-light;
		}

		.ui-state-hover {
			background: $blue-light;
			color: $white;
		}

		.ui-datepicker-month,
		.ui-datepicker-year {
			@include open-sans;
		}

		.ui-datepicker-month,
		.ui-datepicker-year {
			@include font-size(20);
		}

		.ui-datepicker-month { 
			color: $blue-light;
		}

		.ui-datepicker-year {
			color: $black;
		}

		.ui-datepicker-group {
			width: 48%;

			position: static;

			float: left;
			margin: 0 2%;

			&.ui-datepicker-group-first {
				margin-left: 0;
			}

			&.ui-datepicker-group-last {
				margin-right: 0;
			}
		}

		.ui-datepicker-prev,
		.ui-datepicker-next {
			@include size(30, 32);

			position: absolute;
			top: 50%;

			border: none;
			border-radius: 0;
			color: transparent;
			cursor: pointer;
			display: block;
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;

			span {
				top: 0;
				left: 0;

				margin: 0;
			}

			&.ui-state-hover {
				background: transparent;
			}
		}

		.ui-datepicker-prev {
			left: -30px;

			@include breakpoint(0px 500px) {
				top: auto;
				bottom: 0;
				left: 0;
			}

			@include breakpoint(500px 800px) {
				left: -15px;
			}

			@include breakpoint(800px) {
				left: -40px;
			}

			.ui-icon {
				@include icon-datepicker-arrow-left;
			}
		}

		.ui-datepicker-next {
			right: -30px;

			@include breakpoint(0px 500px) {
				top: auto;
				bottom: 0;
				right: 0;
			}

			@include breakpoint(500px 500px) {
				right: -15px;
			}

			@include breakpoint(800px) {
				right: -40px;
			}

			.ui-icon {
				@include icon-datepicker-arrow-right;
			}
		}
	}