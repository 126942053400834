/*------------------------------>
	Templates > Home
<------------------------------*/

	/*	Page Header
	//------------------------------*/

	.page-header-home {
		z-index: 1;

		.page-header-image {
			position: relative;

			@include breakpoint($max-md) {
				height: auto;
				min-height: 250px;
			}

			@include breakpoint($min-md) {
				height: 420px;
			}

			@include breakpoint($min-lg) {
				height: 525px;
			}

			&:after {
				width: 100%;
				height: 100%;

				position: absolute;
				left: 0;
				bottom: 0;
				z-index: 0;

				background: rgba(0,0,0,0.35);
				content: "";

				@include breakpoint($min-md) {
					background: rgba(0,0,0,0);
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
					background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0.35)));
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
					background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
					background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=0 );
				}
			}
		}

		&.fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-pagination {
			position: absolute;
			bottom: 15px;

			.fs-carousel-page {
				background: rgba(255,255,255,0.5);
				opacity: 0.7;
			}
			
			.fs-carousel-page.fs-carousel-active,
			.no-touchevents & .fs-carousel-page:hover {
				background: $white;
				opacity: 1;
			}
		}

		&.fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control {
			@include size(38,92);

			background-color: transparent;
			border-radius: 0;
			outline: none;

			&:before{
				@include size(38,92);
				@include icon-sprite();

				position: absolute;
				top: 0;
				left: 0;

				border: none;
				content: "";
				display: block;
				margin: 0;
				opacity: 0.5;
				transition: opacity 0.15s linear;
			}

			&.fs-carousel-visible:hover {
				background-color: transparent;

				&.fs-carousel-control_previous:before,
				&.fs-carousel-control_next:before {
					opacity: 1;
				}
			}

			&.fs-carousel-control_previous:before {
				background-position: 0 -80px;
			}

			&.fs-carousel-control_next:before {
				background-position: -40px -80px;
			}

			@include breakpoint($max-md) {
				top: auto;
				bottom: -78px;

				@include size(19, 46);

				&:before {
					@include size(19, 46);
				}

				&.fs-carousel-control_previous:before {
					@include scale-sprite(0.5, 0, -80px);
				}

				&.fs-carousel-control_next:before {
					@include scale-sprite(0.5, -40px, -80px);
				}
			}
		}
	}

	.page-header-home-content {
		width: 100%;

		position: relative;
		z-index: 1;

		padding-top: 70px;
		padding-bottom: 30px;
		text-align: center;

		@include breakpoint($max-md) {
			min-height: 250px;
		}

		@include breakpoint($min-sm) {
			padding-top: 80px;
			padding-bottom: 50px;
		}

		@include breakpoint($min-md) {
			position: absolute;
			top: 0;
			bottom: 0;

			padding-top: 0;
			padding-bottom: 0;

			
		}

		.fs-row,
			.fs-cell {
				height: 100%;
			}

			.fs-cell {
				display: flex;
				align-items: center;
			}
	}

	.page-header-home-text {
		@include font-size(27);
		// @include text-shadow;

		color: $white;
		line-height: 1.4;
		margin-bottom: 15px;
		text-shadow: 0 1px 0.35em #000;
		text-transform: uppercase;

		@include breakpoint($min-sm) {
			@include font-size(33);
		}

		@include breakpoint($min-md) {
			@include font-size(40);

			padding-left: 60px;
			padding-right: 60px;
		}

		@include breakpoint($min-lg) {
			@include font-size(60);

			margin-bottom: 0;
		}
	}

	.page-header-home-button {
		display: inline-block;
		margin-top: 20px;

		@include breakpoint($min-sm) {
			
			margin-left: 10px;
			margin-right: 10px;
		}
	}


	/*	Page Quote & Alerts
	//------------------------------*/

	.page-quote-home {
		@include font-smoothing;

		background: $gold;
		color: $black;
		padding: 20px 0 25px;
		text-align: center;
	}

	.page-quote-home-quote {
		@include font-size(18);

		margin-bottom: 5px;

		@include breakpoint($min-sm) {
			@include font-size(26);
		}

		@include breakpoint($min-md) {
			@include font-size(36);
		}

		@include breakpoint($min-lg) {
			@include font-size(42);
		}
	}

	.page-quote-home-author {
		@include font-size(18);
		@include open-sans-italic;

		@include breakpoint($min-sm) {
			@include font-size(24);
		}

		@include breakpoint($min-md) {
			@include font-size(28);
		}

		@include breakpoint($min-lg) {
			@include font-size(32);
		}
	}

	.page-alert-title {
		@include open-sans-bold;
		@include font-size(18);

		margin-bottom: 5px;

		@include breakpoint($min-sm) {
			@include font-size(22);
		}

		@include breakpoint($min-xl) {
			@include font-size(30);
		}

		@include breakpoint($min-xl) {
			@include font-size(36);
		}
	}

	.page-alert-text {
		@include open-sans-italic;

		color: $black;

		@include breakpoint($min-lg) {
			p {
				@include font-size(24);
			}

			padding: 0 40px;
		}

		@include breakpoint($min-xl) {
			p {
				@include font-size(27);
			}
		}
	}


	/*	Page Module Sections
	//------------------------------*/

	.page-module-sections {
		background: $blue;
		color: $white;
		padding: 40px 0 100px;
	}

	.page-module-section {

		@include breakpoint($max-md) {
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint($min-md) {
			
			&:first-child {
				padding-right: 10px;
			}

			&:last-child {
				padding-left: 10px;
			}
		}

		@include breakpoint($min-xl) {
			
			&:first-child {
				padding-right: 30px;
			}

			&:last-child {
				padding-left: 30px;
			}
		}

		.button-outline-dark {
			display: inline-block;
			margin-top: 20px;
			text-transform: uppercase;
		}
	}

	.page-module-section-title {
		@include font-size(26);

		margin-bottom: 0.2em;
		text-transform: uppercase;

		@include breakpoint($min-sm) {
			@include font-size(30);
		}

		@include breakpoint($min-lg) {
			@include font-size(40);
		}

		@include breakpoint($min-xl) {
			@include font-size(44);
		}
	}

	.page-module-section-list {
		margin: 0 0 15px;
		padding: 0;
		list-style: none;
	}

	.page-module-section-list-item {
		@include clearfix;

		position: relative;

		border-top: 1px solid $gray-light;
		margin: 0;
		padding: 15px 70px 15px 0;

		.contain {
			float: left;
		}
	}

	.page-module-section-list-item-title {
		@include font-size(18);
		
		margin-right: 10px;

		@include breakpoint($min-sm) {
			@include font-size(21);
		}
	}

	.page-module-section-list-item-button {
		@include font-size(15);

		position: absolute;
		right: 8px;
		top: 15px;

		border: 1px solid $white;
		border-radius: 3px;
		color: $white;
		display: inline-block;
		line-height: 1;
		padding: 4px 6px;
		text-transform: uppercase;

		&:hover {
			background-color: $white;
			color: $blue-light;
		}
	}

	/* Campus News */

	.home-posts-last {
		border-top: 1px solid $gray-light;
		margin-bottom: 30px;
		padding-top: 15px;

		h2 {
			@include font-size(28);

			font-weight: 300;
			line-height: 1.2;
			margin-top: 5px;
			text-transform: uppercase;
		}

		p {
			@include font-size(18);
		}
	}

	/* Upcoming Events */

	.events-upcoming-date {
		@include font-size(16);

		color: #7e7e7e;
		margin-right: 10px;
	}


	/*	Homepage Schools + Quicklinks
	//------------------------------*/

	.home-schools {
		position: relative;
		z-index: 2;
		
		background-color: #ebebeb;
		padding: 35px 0;
	}

	.home-school-header {

		h2 {
			@include open-sans-bold;

			color: $blue-light;
			text-transform: uppercase;

			@include breakpoint($md-lg) {
				@include font-size(20);
			}
		}
	}

	.home-school-footer {
		position: relative;

		@include breakpoint($min-lg) {
			padding: 0 20px;	
		}

		@include breakpoint($min-xl) {
			padding: 0 50px;
		}
	}

	.home-school-button {
		position: relative;

		border: 2px solid #ebebeb;
		color: $blue-light;
		display: block;
		padding: 10px 20px;
		text-transform: uppercase;

		&:after {
			@include size(0);

			position: absolute;
			top: 16px;
			right: 20px;

			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 8px solid $blue-light;
			content: "";
			display: block;

			vertical-align: middle;
		}

		&.active {
			background-color: $gold;

			&:after {
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-top: none;
				border-bottom: 8px solid $blue-light;
			}
		}

		@include breakpoint($md-lg) {
			padding: 10px 40px 10px 10px;
		}

		@include breakpoint($min-lg) {
			padding: 10px 40px;
		}

		@include breakpoint($min-xl) {
			padding: 10px 50px;
		}
	}

	.home-school-icon {
		width: 100%;
		height: 280px;

		background-position: center center;
		background-repeat: no-repeat;
		margin: 10px 0;

		@include breakpoint($md-lg) {
			height: 200px;
			background-size: 75%;
		}

		@include breakpoint($lg-xl) {
			background-size: 80%;
		}
	}

	.home-school {
		text-align: center;

		> a {
			&:hover {
				h2 {
					color: $black;
				}
			}
		}

		@include breakpoint($min-md) {
			border-right: 2px solid $white;

			&:last-child {
				border-right: none;
			}
		}

		&.home-school-elementary {

			.home-school-icon {
				background-image: url(../images/site/icon-elementary.png);

				.svg & {
					background-image: url(../images/site/icon-elementary.svg);
				}
			}
		}

		&.home-school-middle {
			@include breakpoint($max-md) {
				margin-top: 30px;
			}

			.home-school-icon {
				background-image: url(../images/site/icon-middle.png);

				.svg & {
					background-image: url(../images/site/icon-middle.svg);
				}
			}
		}

		&.home-school-high {
			@include breakpoint($max-md) {
				margin-top: 30px;
			}

			.home-school-icon {
				background-image: url(../images/site/icon-high.png);

				.svg & {
					background-image: url(../images/site/icon-high.svg);
				}
			}
		}
	}

	.home-school-links {
		@include font-size(20);

		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 1;

		margin: 0;

		background: $blue-header-gradient-light;
		background: -moz-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -webkit-gradient(left top, left bottom, color-stop(0%, $blue-header-gradient-light), color-stop(100%, $blue-header-gradient-dark));
		background: -webkit-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -o-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: -ms-linear-gradient(top, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		background: linear-gradient(to bottom, $blue-header-gradient-light 0%, $blue-header-gradient-dark 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue-header-gradient-light', endColorstr='$blue-header-gradient-dark', GradientType=0 );

		border: 2px solid #ebebeb;
		opacity: 0;
		padding: 20px 25px 25px;
		pointer-events: none;
		visibility: hidden;
		text-align: left;
		transition: opacity 0.2s linear, visibility 0.2s linear;

		@include breakpoint($min-lg) {
			margin: 0 20px;
		}

		@include breakpoint($min-xl) {
			margin: 0 50px;
		}

		li {
			margin: 0;
			text-align: center;

			&, a {
				@include font-size(20);

				color: $white;
				display: block;
			}

			a {
				@include font-smoothing;

				padding: 3px 0;

				&:hover {
					color: $gold;
				}
			}

			h3 {
				@include font-size(22);

				margin-bottom: 15px;
				text-transform: uppercase;
			}
		}

		.home-school-button.active + & {
			opacity: 1;
			pointer-events: all;
			visibility: visible;
		}
	}


	/*	Home Portal Links
	//------------------------------*/

	.home-portals {
		@include font-size(0);

		background: $white;
		padding: 40px 0;

		@include breakpoint($min-lg) {
			.fs-lg-fifth {
				display: inline-block;
				float: none;
				vertical-align: middle;
			}
		}
	}

	.home-portals-heading,
	.home-portal {
		@include font-size(28);
		@include open-sans-bold;

		color: $blue-light;
		text-transform: uppercase;

		@include breakpoint($min-lg) {
			@include font-size(20);
		}

		@include breakpoint($min-xl) {
			@include font-size(24);
		}
	}

	.home-portals-heading {
		line-height: 1;
		text-align: center;

		@include breakpoint($min-lg) {
			margin-top: 10px
		}
	}

	.home-portal {
		position: relative;

		background-position: center center;
		padding: 20px;
		text-align: center;

		&:after {
			width: 100px;
			height: 2px;

			position: absolute;
			top: 100%;
			left: 50%;

			border-bottom: 2px solid $gray-light;
			content: "";
			display: inline-block;
			margin-left: -50px;

			@include breakpoint($min-lg) {
				width: 2px;
				height: 50px;

				top: -8px;
				left: auto;
				right: 0;

				border-bottom: none;
				border-right: 2px solid $gray-light;
				margin-left: 0;
			}

			@include breakpoint($min-xl) {
				top: 0;
			}
		}

		&:last-child {
			a {
				@include font-size(21);
				position: relative;

				display: inline-block;
				line-height: 1;
				padding-left: 50px;
				vertical-align: middle;
				
				&:before {
					@include icon-cart;

					position: absolute;
					left: 0;
					top: -5px;

					content: "";
					display: inline-block;

					@include breakpoint($lg-xl) {
						top: 0;
					}
				}
			}

			&:after {
				content: none;
			}
		}

		@include breakpoint($md-lg) {
			&:nth-child(3n+1) {
				clear: both;
			}

			&:nth-child(4):after {
				content: none;
			}
		}

		img {
			width: 100%;
			max-width: 250px;
			height: auto;
		}

		a {
			color: $blue-light;

			@include breakpoint($min-lg) {
				vertical-align: middle;
			}
		}

		@include breakpoint($min-lg) {
			padding: 0 20px;
		}
	}



	/*	Page Content Sections
	//------------------------------*/

	.home-page-sections {
		background-color: $gray-light;
	}

	.home-page-section {
		position: relative;

		padding: 75px 20px 35px;
		text-align: center;

		@include breakpoint($max-lg) {

			&:last-child {
				margin-top: 70px;

				&:before {
					width: 200%;
					height: 70px;

					position: absolute;
					top: -70px;

					border-top: 70px solid $white;
					content: "";
					display: block;
					margin-left: -50%;
				}
			}
		}

		@include breakpoint($min-lg) {
			padding-bottom: 190px;

			&:first-child {
				padding-left: 0;
				padding-right: 30px;

				&:before {
					width: 2px;
					height: 80%;

					position: absolute;
					right: -11px;
					top: 12%;

					border-right: 2px solid $gray;
					content: "";
					display: block;
					margin-left: 1.04166667%;
				}
			}

			&:last-child {
				padding-left: 30px;
				padding-right: 0;
			}
		}

		@include breakpoint($min-xl) {
			padding-bottom: 120px;

			&:first-child:before {
				right: -14px;
			}
		}
	}

	.home-page-section-title {
		@include font-size(28);

		line-height: 1.1;

		@include breakpoint($min-sm) {
			@include font-size(32);
		}

		@include breakpoint($min-xl) {
			@include font-size(36);
		}
	}

	.home-page-section-icon {
		@include size(130, 130);

		position: absolute;
		left: 50%;
		top: -70px;

		background-color: $white;
		border-radius: 100%;
		margin-left: -65px;
	}

	.home-page-section-description {
		margin-top: 20px;

		@include breakpoint($max-md) {
			text-align: left;
		}
	}

	.home-page-section-buttons {
		margin-top: 30px;
		text-align: center;

		@include breakpoint($min-lg) {
			width: 100%;

			position: absolute;
			bottom: 40px;

			margin-left: -20px;
		}

		.button-outline-dark {
			display: inline-block;
			text-transform: uppercase;

			@include breakpoint($max-xl) {
				max-width: 320px;
				display: block;
				margin: 0 auto 10px;
			}

			@include breakpoint($min-xl) {
				margin-right: 20px;
				padding-left: 30px;
				padding-right: 30px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
