/*------------------------------>
	Document > Icons
<------------------------------*/

	/*	Logo
	//------------------------------*/

	@mixin icon-logo {
		background: transparent url(../images/site/logo-home.png) no-repeat;
		background-size: 401px 369px;
	}

	@mixin icon-logo-main {
		background: transparent url(../images/site/logo.png) no-repeat;
		background-size: 580px 44px;

		.svg & {
			background-image: url(../images/site/logo.svg);
		}
	}

	@mixin icon-column {
		background: transparent url(../images/site/column-red.png) center center no-repeat;
		background-size: 1500px 1500px;

		.svg & {
			background-image: url(../images/site/column-red.svg);
		}
	}

	@mixin icon-column-red {
		background: transparent url(../images/site/column-red-light.png) center center no-repeat;
		background-size: 1500px 1500px;

		.svg & {
			background-image: url(../images/site/column-red-light.svg);
		}
	}

	@mixin icon-column-gray {
		background: transparent url(../images/site/column-gray.png) center center no-repeat;
		background-size: 1500px 1500px;

		.svg & {
			background-image: url(../images/site/column-gray.svg);
		}
	}


	/*	Calendar Datepicker
	//------------------------------*/

	@mixin icon-datepicker-arrow-left {
		@include size(30, 32);
		@include icon-sprite(-80px, -80px);
	}

	@mixin icon-datepicker-arrow-right {
		@include size(30, 32);
		@include icon-sprite(-110px, -80px);
	}


	/*	Social Media
	//------------------------------*/

	@mixin icon-facebook-white {
		@include icon-sprite(-40px, 0);
	}

	@mixin icon-twitter-white {
		@include icon-sprite(0, 0);
	}

	@mixin icon-facebook-red {
		@include icon-sprite(-40px, -40px);
	}

	@mixin icon-twitter-red {
		@include icon-sprite(0, -40px);
	}


	/*	Misc.
	//------------------------------*/

	@mixin icon-car {
		@include size(50, 16);
		@include icon-sprite(-80px, 0);
	}

	@mixin icon-cart {
		@include size(40, 38);
		@include icon-sprite(-80px, -40px);
	}

	@mixin icon-search-white {
		@include icon-sprite(-140px, 0);
	}

	@mixin icon-search-gold {
		@include icon-sprite(-140px, -40px);
	}
