/*------------------------------>
	Components > Carousel
<------------------------------*/

	.carousel {
		@include open-sans-italic;
		@include font-smoothing;
		@include icon-column;

		background-color: $red;
		color: $white;
		overflow: hidden;
		padding: 45px 0 60px;
		text-align: center;

		.fs-carousel-enabled.fs-carousel-contained,
		.fs-carousel-enabled.fs-carousel-contained .fs-carousel-container,
		.fs-carousel-enabled.fs-carousel-contained .fs-carousel-viewport,
		.fs-carousel-enabled.fs-carousel-contained .fs-carousel-wrapper {
			overflow: visible;
		}

		.fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-control {
			@include size(38,92);

			background-color: transparent;
			border-radius: 0;
			outline: none;

			&:before{
				@include size(38,92);
				@include icon-sprite();

				position: absolute;
				top: 0;
				left: 0;

				border: none;
				content: "";
				display: block;
				margin: 0;
				opacity: 0.5;
				transition: opacity 0.15s linear;
			}

			&.fs-carousel-visible:hover {
				background-color: transparent;

				&.fs-carousel-control_previous:before,
				&.fs-carousel-control_next:before {
					opacity: 1;
				}
			}

			&.fs-carousel-control_previous:before {
				background-position: 0 -80px;
			}

			&.fs-carousel-control_next:before {
				background-position: -40px -80px;
			}

			@include breakpoint($max-md) {
				top: auto;
				bottom: -78px;

				@include size(19, 46);

				&:before {
					@include size(19, 46);
				}

				&.fs-carousel-control_previous:before {
					@include scale-sprite(0.5, 0, -80px);
				}

				&.fs-carousel-control_next:before {
					@include scale-sprite(0.5, -40px, -80px);
				}
			}
		}

		.fs-carousel.fs-light.fs-carousel-enabled .fs-carousel-pagination {
			margin-top: 50px;

			@include breakpoint($min-md) {
				margin-top: 36px;
			}

			.fs-carousel-page {
				background: $red-pale;
				opacity: 0.7;
			}
			
			.fs-carousel-page.fs-carousel-active,
			.no-touchevents & .fs-carousel-page:hover {
				background: $white;
				opacity: 1;
			}
		}
	}

	.carousel-slide {
		padding: 0 15px;

		.fs-carousel-enabled &.fs-carousel-item {
			opacity: 0;
			transition: opacity 0.15s linear;

			&.fs-carousel-visible {
				opacity: 1;
			}
		}

		@include breakpoint($min-sm) {
			padding: 0 40px;
		}

		@include breakpoint($min-md) {
			padding: 0 120px;
		}


		@include breakpoint($min-lg) {
			padding: 0 150px;
		}
	}

	.carousel-slide-image {
		width: 225px;
		height: 225px;

		border: 5px solid $white;
		border-radius: 100%;
		margin: 0 auto 36px;
		overflow: hidden;
	}

	.carousel-slide-title {
		@include font-size(24);

		@include breakpoint($min-sm) {
			@include font-size(30);
		}

		@include breakpoint($min-lg) {
			@include font-size(36);
		}
	}

	.carousel-slide-description {
		margin-top: 36px;
	}
